/**
 * Log In Page
 * 
 * Prompts users for an access code to access their game. If the access code
 * is valid, will fetch all of the data for the game, assign them to a team,
 * and then go to the settings page.
 */

import React, { useContext, useEffect, useState, useRef } from 'react'
import { navigate } from 'gatsby'
import ReactCodeInput from 'react-code-input'
import { deviceDetect } from 'react-device-detect'


import { isLoggedIn } from '../utils/auth'
import Background from '../components/background'
import API from '../utils/api'
import { getUserID, setUser } from '../utils/auth'
import { updateHuntData } from '../utils/hunt-data'
import { setTeamData, getCurrentTeamData } from '../utils/team-data'
import Fade from 'react-reveal/Fade'
import Rotate from 'react-reveal/Rotate'
import ImageWatchHand from '../components/images/watch-hand'
import WatsonLogoSVG from '../components/svg/logo'

import '../sass/app/_login.scss'
import AppContext from '../utils/context'

const Login = () => {
  const context = useContext(AppContext)
  const [access_code, setAccessCode] = useState(``)
  const codeRef = useRef()

  // grab site data from the context
  const site_data = context.site_data

  // if they're already logged in, go to the main index page
  useEffect(() => {
    if (isLoggedIn()) {
      navigate(`/`)
    }
  }, [])

  // handle the code input changes
  const handleUpdate = (code) => {
    setAccessCode(code)
    // if the code is 6 digits long, submit it automatically
    if (code.length == 6) {
      submitCode(code)
    }
  }

  // handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    submitCode(access_code)
  }

  // submit the code itself
  const submitCode = (code) => {
    code = code.toLowerCase()
    context.setLoading(true)

    // grab the device data to store
    var device_data = deviceDetect()

    // post the data to try and log the user in
    API.post(`session/${code}/`, device_data)
      .then(res => {
        //console.log(res)
        //console.log(`Access Code matched! Setting the active user.`)
        const userId = getUserID()

        // set the local team data with the response
        setTeamData(res.data.team)
        setUser({
          guid: userId,
        })

        // update the hunt data
        updateHuntData(res.data.team.hunt_id, res.data.team.start, res.data.team.direction).then(res => {
          const teamData = getCurrentTeamData()
          if (teamData.start_time) {
            // hunt has started already, skip to settings
            navigate(`/settings/`)
          } else {
            // console.log(`navigating to get started`)
            navigate(`/`)
          }
        })
      })
      // catch any errors when logging in, usually due to an incorrect code
      .catch(error => {
        context.setLoading(false)
        let error_msg = error
        if (error.response !== undefined) {
          error_msg = error.response.status
        }
        codeRef.current.clear()
        context.showAlert(`Incorrect Code!`)
        console.error(error_msg)
      })
  }

  return (
    <Fade>
      <div className={`login-page`} style={{ minHeight: `100vh`, overflow: `hidden`, maxWidth: `100%` }}>
        <Background alt="Watson Login Screen" filename="login.jpg" />

        <div
          style={{
            //paddingTop: '100px',
            margin: `auto`,
            //maxWidth: '400px',
            position: `relative`,
            zIndex: `10`,
          }}>
          <div>
            {site_data.greeting_text && (
              <>
                <Fade top big delay={1000}>
                  <div
                    className={`py-4 px-4 mb-5`}
                    style={{
                      backgroundColor: `#F5F2ED`,
                      boxShadow: `2px 2px 5px rgba(189, 186, 186, 0.5)`,
                    }}>
                    <div
                      style={{
                        maxWidth: `400px`,
                        margin: `auto`,
                      }}>
                      <WatsonLogoSVG />
                    </div>
                  </div>
                </Fade>
                <div
                  style={{
                    maxWidth: `400px`,
                    margin: `auto`,
                  }}>
                  <Fade left big delay={1000}>
                    <h1
                      className={`text-left text-uppercase text-white h1 title mb-0 px-3`}
                      dangerouslySetInnerHTML={{ __html: site_data.greeting_text }}
                    />
                  </Fade>
                  <Fade right big delay={1500}>
                    <p
                      className={`h6 lead text-center text-white mb-5 px-4`}
                      style={{
                        marginTop: `-20px`,
                        lineHeight: `1.4`,
                        fontStyle: `italic`,
                      }}>
                      Enter the 6 digit code
                    </p>
                  </Fade>
                </div>
              </>
            )}

            <Fade bottom delay={2000}>
              <form
                className={`access-form-group text-center`}
                method="post"
                onSubmit={handleSubmit}>
                <div className="form-group text-center py-3 d-inline-block px-3">
                  <ReactCodeInput
                    ref={codeRef}
                    type="tel"
                    pattern="[0-9]*"
                    fields={6}
                    onChange={handleUpdate}
                    label={`Enter the 6 digit code`}
                    novalidate
                  />
                </div>

                <button type="submit" className="sr-only">
                  Submit
                </button>
              </form>
              <div className="text-center text-white">
                <small className="copyright" dangerouslySetInnerHTML={{ __html: site_data.copyright_text }} />
                {site_data.terms_headline && 
                  <small><a className="d-block text-white" href="/terms" target="_blank" rel="noopener noreferer">{site_data.terms_headline}</a></small>
                }
              </div>
            </Fade>
          </div>
        </div>

        <Rotate top right delay={2500}>
          <div
            style={{
              position: `absolute`,
              right: `0px`,
              bottom: `60px`,
            }}>
            <ImageWatchHand />
          </div>
        </Rotate>
      </div>
    </Fade>
  )
}

export default Login